import { http } from '@/utils/http'
export default{
    getUserInfo(){
        // 获取用户信息
        return http({
            url:`/members/api/v1/userInfo`,
            method:'get'
        })
    },
    editArea(params: string){
        // 申请修改服务区域
        return http({
            url:`/members/api/v1/userInfo/_area/${params}`,
            method:`put`
        })
    },
    editUserInfo(params: object){
        // 修改用户个人信息
        return http({
            url:`/members/api/v1/userInfo`,
            method:'put',
            data:params
        })
    },
    getIntergral(){
        // 获取用户积分余额
        return http({
            url:`/members/api/v1/integral`,
            method:'get'
        })
    },
     intergralRecord(params: object){
         // 查询积分余额记录
         return http({
             url:`/members/api/v1/integrals`,
             method:'get',
             params:params
         })
     },
     getAddressList(params: object){
         // 查询地址列表
         return http({
             url:`/members/api/v1/address`,
             method:'GET',
             params:params
         })
     },
     deleteAddress(id: string){
         // 删除用户地址
         return http({
             url:`/members/api/v1/address/${id}`,
             method:'DELETE'
         })
     },
     addAddress(params: object){
         // 新增地址
         return http({
             url:`/members/api/v1/address`,
             method:'post',
             data:params
         })
     },
     getCollageList(){
         // 获取收藏店铺列表
         return http({
             url:`/members/api/v1/shops/collection`,
             method:'get',
         })
     },
     delCollage(id: string){
         // 取消收藏店铺
         return http({
             url:`/members/api/v1/shops/${id}/collection`,
             method:'delete'
         })
     },
     editPassword(params){
         // 修改登录密码
         return http({
             url:`/members/api/v1/reset_password_login`,
             method:'put',
             data:params
         })
     },
     getCode(phone){
         // 获取手机验证码
         return http({
             url:`/members/api/v1/code/${phone}`,
             method:'get'
         })
     },
     feedBack(params){
         // 意见反馈
         return http({
             url:`/members/api/v1/feedback`,
             method:'post',
             data:params
         })
     },
     getChina(){
        // 获取全国地区id
        return http({
            url:`/api/v1/china`,
            method:'get'
        })
    },
    editPayPsw(params: object){
        // 修改支付密码
        return http({
            url:`/members/api/v1/reset_password_pay`,
            method:'put',
            data:params
        })
    },
    upload(params: object){
        // 上传
        return http({
            url:`/api/v1/attachment`,
            method:'post',
            data:params,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },
    rechange(params: object) {
        // 充值
        return http({
            url:`/members/api/v1/recharge/_ali`,
            method:'get',
            params:params
        })
    },
    getMessage(params: object){
        // 获取消息列表
        return http({
            url:`/members/api/v1/messages`,
            method:'get',
            params:params
        })
    },
    delMessage(params: string){
        // 删除消息
        return http({
            url:`/members/api/v1/messages/${params}`,
            method:`DELETE`
        })
    },
    hasReadMsg(params: string){
        // 标记已读
        return http({
            url:`/members/api/v1/messages/${params}`,
            method:'get'
        })
    },
    getNoRead(){
        // 获取未读消息
        return http({
            url:`/members/api/v1/messagesNum`,
            method:'get',
        })
    },
    changePhone(params: object){
        // 改变手机号
        return http({
            url:`/admin/api/v1/users/reset/phone`,
            method:'put',
            data:params
        })
    }
}