
















































import { Vue, Component, Provide } from "vue-property-decorator";
import Nav from "@/components/header.vue";
import serve from '@/serve/mine'
@Component({
  components: {
    Nav,
  },
})
export default class Recharge extends Vue {
  @Provide() num = "";
  @Provide() radio = "2";
  async submit() {
    const data = {
      amount:this.num,
      returnUrl:`https://h5.glhgg.com/rechargeTips` // z支付后返回地址
    }
    const res = await serve.rechange(data)
    const paymentLink = res.data
    const div = document.createElement('div')
    div.innerHTML = paymentLink
    div.style.display = 'none'
    document.body.appendChild(div)
    document.forms[0].submit()
  }
}
